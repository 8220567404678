<template>
  <div class="login-page">
    <div class="login-form">
      <div class="login-title">厅堂邮约活动管理系统</div>
      <el-form ref="loginForm" :model="form" :rules="rules" class="login-form-item">
        <el-form-item label="" prop="account">
          <el-input placeholder="请输入账号" clearable v-model="form.account">
            <template slot="prepend">账 号</template>
          </el-input>
        </el-form-item>
        <el-form-item label="" prop="pwd">
          <el-input type="password" show-password clearable placeholder="请输入密码" v-model="form.pwd" @change="onSubmit('loginForm')">
            <template slot="prepend">密 码</template>
          </el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" :loading="loading" style="width: 81%;" @click="onSubmit('loginForm')">登 录</el-button>
      <div class="bottom">
        <el-button type="text" @click="onforgot">忘记密码</el-button>
      </div>
    </div>
    <!-- 底部备案号 -->
    <div class="bottom-beian">
      &copy; Copyright {{ new Date().getFullYear() }} 厅堂邮约 
      <span style="margin-left: 10px;">备案号：</span>
      <a class="beian-link" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">豫ICP备2023011655号-1</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HuijiliWebIndex',

  data() {
    const validateAccount = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请输入账号'))
      } else {
        callback()
      }
    }
    const validatePwd = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请输入密码'))
      } else {
        callback()
      }
    }
    return {
      form: {
        account: '',
        pwd: ''
      },
      rules: {
        account: [
          { validator: validateAccount, trigger: 'blur' }
        ],
        pwd: [
          { validator: validatePwd, trigger: 'blur' }
        ]
      },
      loading: false
    };
  },

  mounted() {
    
  },

  methods: {
    onforgot() {
      this.$alert('请联系管理员重置密码', '提示', {
          confirmButtonText: '确定',
          type: 'info'
      });
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          this.$http({
            method: 'post',
            url: 'user_login',
            params: {
              username: this.form.account,
              pwd: this.form.pwd
            }
          })
          .then(res => {
            this.loading = false
            if (res.errno == 0) {
              if (res.data.state == '1') {
                this.$message.success('登录成功')
                localStorage.setItem('userInfo', JSON.stringify(res.data))
                localStorage.setItem('hjlToken', res.token)
                this.$router.push({
                  path: '/home'
                })
              } else {
                this.$message.error('该账号已停用，请更换账号登录')
              }
            } else {
              this.$message.error('账号密码有误，登录失败')
            }
          })
          .catch(err => {
            this.loading = false
            this.$message.error('登录异常')
            console.error('登录异常：', err.message);
          })
          
        } else {
          return false;
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.login-page {
  width: 100%;
  height: 100%;
  background: url('../../assets/login-bg.jpg') no-repeat;
  margin: 0px;
  background-size: 100% 100%;
  background-attachment: fixed;
  position: relative;

  .login-form {
    width: 400px;
    height: 400px;
    background: #FFFFFF;
    position: absolute;
    right: 150px;
    top: 150px;
    box-shadow: 2px 2px 5px 5px rgba($color: #000000, $alpha: 0.05);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    box-sizing: border-box;
    padding: 30px;

    .login-title {
      font-size: 18px;
      font-weight: 600;
      margin: 20px 0px;
    }
    .login-form-item {
      margin: 20px 0px;

      .el-input {
        // margin: 10px 0px;
      }
    }
    .bottom {
      text-align: right;
      width: 100%;
      margin-top: 20px;
    }
  }
}
::v-deep .el-form-item__error {
  padding-left: 80px !important;
}

.bottom-beian {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: #666;
  font-size: 14px;
}
.beian-link {
  text-decoration: none;
  color: #666;
}
</style>